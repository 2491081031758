mat-chip {
    min-height: 20px !important;

    &.prenotazione-pagata {
        background-color: #49cc90 !important;
        color: white !important;
    }

    &.prenotazione-pagata-in-parte {
        background-color: #ff8d72 !important;
        min-height: 20px !important;
    }
    &.prenotazione-non-pagata {
        background-color: #fd5d93 !important;
    }
}
