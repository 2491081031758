@use "@angular/material"as mat;
@include mat.core();

@import "./custom-component-themes.scss";


$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

// my custom palette
$back-office-palette: (50: #e1f5fe,
    100: #b3e5fc,
    200: #81d4fa,
    300: #4fc3f7,
    400: #29b6f6,
    500: #03a9f4,
    600: #039be5,
    700: #058fb2,
    800: #0277bd,
    900: #01579b,
    A100: #80d8ff,
    A200: #40c4ff,
    A400: #00b0ff,
    A700: #0091ea,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $light-primary-text,
    ),
);

$ombrellone-gestionale-primary: mat.define-palette($back-office-palette, 700);
$ombrellone-gestionale-accent: mat.define-palette(mat.$pink-palette,
    A200,
    A100,
    A400);

$ombrellone-gestionale-warn: mat.define-palette(mat.$red-palette);

$ombrellone-gestionale-theme: mat.define-light-theme((color: (primary: $ombrellone-gestionale-primary,
            accent: $ombrellone-gestionale-accent,
            warn: $ombrellone-gestionale-warn,
        ),
    ));

@include mat.all-component-themes($ombrellone-gestionale-theme);

@include custom-components-theme($ombrellone-gestionale-theme);
// @include custom-components-theme($ombrellone-gestionale-theme); // 👈 changed

// Import functions, variables, and mixins needed by other Bootstrap files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Import Bootstrap Reboot
@import "~bootstrap/scss/root"; // Contains :root CSS variables used by other Bootstrap files
@import "~bootstrap/scss/reboot";

@import "~bootstrap/scss/containers"; // Add .container and .container-fluid classes
@import "~bootstrap/scss/grid"; // Add the grid system

@import "~bootstrap/scss/utilities"; // Configures the utility classes that should be generated
@import "~bootstrap/scss/utilities/api"; // Generates the actual utility classes

@import "scss/components/am-components";

.mat-form-field {
    display: block !important;
    margin-bottom: 8px;
}

.mat-menu-item {
    line-height: 32px !important;
    height: 32px !important;
    padding: 0 8px !important;
}

.mat-menu-item .mat-icon {
    margin-right: 8px !important;
}

[data-initials]:before {
    background: #ffffff;
    color: #058fb2;
    opacity: 1;
    content: attr(data-initials);
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 0.5em;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// ::ng-deep .mat-toolbar-row,
// .mat-toolbar-row {
//     height: 44px !important;
// }

// .mat-subheader {
//   font-size: 18px !important;
//   font-weight: 900 !important;
// }

// .nav-list-icon {
//   margin: 0 12px 0 12px;
// }

div.umbrella {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 35px;
}

div.umbrella>div {
    line-height: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    width: 35px;
    text-align: center;
    background-color: white;
    margin-top: 4px;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
    background: #000;
    opacity: 0.35 !important;
}

.mat-simple-snackbar-action>button {
    color: white !important;
}

table.prenotazioni>tbody>tr.mat-row:hover {
    cursor: pointer;
    background: #b4b4b433;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    overflow-y: scroll;
    background: #fafafa;
    box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
    background: #058fb2;
    border-radius: 10px;
}