
@mixin notification-theme($notifications-theme) {
    $default-color: map-get($notifications-theme, default);
    $info-color: map-get($notifications-theme, info);
    $success-color: map-get($notifications-theme, success);
    $warn-color: map-get($notifications-theme, warning);
    $error-color: map-get($notifications-theme, error);
    .default-notification-overlay,
    .info-notification-overlay,
    .success-notification-overlay,
    .warning-notification-overlay,
    .error-notification-overlay {
        border-left: 4px solid;
        &::before {
            font-family: "Material Icons";
            float: left;
            font-size: 24px;
            // because we want spaces to be considered in content
            // https://stackoverflow.com/questions/5467605/add-a-space-after-an-element-using-after
            white-space: pre;
        }
    }
    .default-notification-overlay {
        border-left-color: $default-color;
        &::before {
            color: #fff;
        }
    }
    .info-notification-overlay {
        background-color: rgb(2, 136, 209);
        border-left-color: $info-color;
        &::before {
            content: "\e88e  ";
            color: $info-color;
        }
    }
    .success-notification-overlay {
        background-color: rgb(46, 125, 50);
        border-left-color: $success-color;
        &::before {
            content: "\e86c  ";
            color: $success-color;
        }
    }
    .warning-notification-overlay {
        background-color: rgb(237, 108, 2);
        border-left-color: $warn-color;
        &::before {
            content: "\e002  ";
            color: $warn-color;
        }
    }
    .error-notification-overlay {
        background-color: rgb(211, 47, 47);
        border-left-color: $error-color;
        &::before {
            content: "\e000  ";
            color: $error-color;
        }
    }
}
